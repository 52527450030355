<template>
  <div class="contatain">
    <div class="flex-row">
      <el-input
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.keyword"
      />
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getScreenWords(true)"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog('editKeyword')"
        >添加</el-button
      >
    </div>
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="screenWords"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="650"
          v-loading="loading"
        >
          <el-table-column prop="keyword" label="关键词"> </el-table-column>
          <el-table-column prop="createName" label="操作人"> </el-table-column>
          <el-table-column prop="createTime" label="添加时间">
              <template slot-scope="scope">
                {{timestampToDate(scope.row.createTime)}}

              </template>
             </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('editKeyword', scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteScreenWord(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
      <editKeyword @getScreenWords="getScreenWords" :editForm="editForm" ref="editKeyword" />
    </div>
  </div>
</template>
<script>
import { timestampToDate } from "@tools";
import editKeyword from './components/editKeyword'
export default {
  components: {
    editKeyword
  },
  data() {
    return {
      loading: false,
      params: {
        limit: 10,
        page: 1,
        keyword: ''
      },
      count: 0,
      editForm: null,
      screenWords: []
    };
  },
  async created() {
    this.getScreenWords();
  },
  mounted() {},
  methods: {
    async getScreenWords(search) {
      this.loading = true;
      if (search) {
        this.params.page = 1
      }
      const { data, count } = await this.$api.log
        .getScreenWords(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.screenWords = data;
      this.count = count;
    },
    handleSizeChange(size) {
      this.params.limit = size;
      this.getScreenWords();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getScreenWords();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        this.editForm = form
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    deleteScreenWord(id) {
      this.$confirm("此操作将永久删除该屏蔽词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.log.deleteScreenWord(id);
          this.getScreenWords();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    timestampToDate
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
</style>