<template>
  <el-dialog width="25%" :title="title" :visible.sync="showDialog">
    <el-form :model="form" label-width="60px" ref="aa">
      <el-form-item label="关键词">
        <el-input v-model="form.keyword" size="mini" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        :loading="loading"
        size="mini"
        @click="editKeyword"
        type="primary"
      >
        确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { timestampToDate, formatSeconds } from "@tools";
export default {
  name: "editKeyword",
  props: {
    editForm: {
      default: null,
    },
  },
  mounted() {},
  data() {
    return {
      title: "",
      showDialog: false,
      loading: false,
      form: {
        keyword: "",
      },
    };
  },
  methods: {
    timestampToDate,
    formatSeconds,
    async editKeyword() {
      this.loading = true;
      this.form.createName = this.$store.state.userMsg.nickname;
      await this.$api.log.editScreenWord(this.form).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$emit("getScreenWords");
    },
  },
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.editForm && Object.assign(this.form, this.editForm);
      } else {
        this.form = this.$options.data().form;
      }
    },
  },
};
</script>